
.project-card {
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: 100%;
    min-height:250px;
    box-shadow: 
    5px 5px 15px rgba(38, 75, 103, 0.3), 
        -7px -7px 15px rgb(244, 246, 249); 
        transition: all 0.3s ease;
  }
  
  .project-card-content {
    display: flex;
    flex-direction: column;
    flex: 2;
    background-color: transparent;
    position: relative;
    z-index: 2;
    padding:10px;
  }
  
  .project-card-image {
    flex: 1;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    mask-image: linear-gradient(to right, transparent 30%, black 95%);
    -webkit-mask-image: linear-gradient(to right, transparent 30%, black 95%);
    transition: mask-image 0.3s ease, -webkit-mask-image 0.3s ease;
  }

/*   
  .project-card:hover .project-card-image {
    mask-image: linear-gradient(to right, transparent 20%, black 60%);
    -webkit-mask-image: linear-gradient(to right, transparent 20%, black 60%);
  } */
  
  .project-card-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40%; 
    height: 100%;
    background: linear-gradient(to right, transparent, transparent);
    pointer-events: none;
    transition: width 0.3s ease;
  }
  
  .project-card:hover .project-card-image::before {
    width: 60%; 
  }